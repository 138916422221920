import React from "react";
import Layout from "../components/Layout/Layout";
import "../styles/Plan.css";

const Plan = () => {
  return (
    <Layout title={"Plan as - Ecommerce App"}>
      <div className="plan-container">
        <h1 className="plan-title">Plan Details</h1>
        <div className="plan-content">
          <div className="plan-item">
            <h2 className="item-titlee">ગેંગ મોડ્યુલ્સ:</h2>
            <p className="item-descriptionn">
              વિવિધ ઑનલાઇન આવક સ્રોતો પર વ્યાપક તાલીમ સામગ્રી મેળવવાની શક્યતા.
            </p>
          </div>
          <div className="plan-item">
            <h2 className="item-titlee">પોર્ટ કોમ્યુનિટી:</h2>
            <p className="item-descriptionn">
              સમર્થન અને સહયોગ માટે સમાન વિચારો ધરાવતા લોકોને જોડાવા માટે.
            </p>
          </div>
          <div className="plan-item">
            <h2 className="item-titlee">સાધન લાઇબ્રેરી:</h2>
            <p className="item-descriptionn">
              તમારી કમાણીની ક્ષમતાઓને વધારવા માટે સાધનો અને સંસાધનોમાં વિશેષ લાભ.
            </p>
          </div>
          <div className="plan-item">
            <h2 className="item-titlee">નિયમિત અપડેટ્સ:</h2>
            <p className="item-descriptionn">
              ડિજિટલ બિઝનેસ ક્ષેત્રના તાજેતરના ટ્રેન્ડ્સ અને તકોથી માહિતી.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Plan;

import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import "../styles/Payment.css";  // Make sure to create this file

const Payment = () => {
  const navigate = useNavigate(); // Hook to handle navigation

  const handleLoginRedirect = () => {
    navigate('/login'); // Redirect to the login page
  };

  return (
    <Layout title={"Payment us - Ecommer app"}>
      <div className="payment-container">
        <div className="payment-images">
          <div className="payment-item">
          <p className="payment-caption">Google Pay</p>
            <img src="/image/googlepay.jpg" alt="Google Pay" className="payment-image" />
          </div>

          <div className="payment-item">
          <p className="payment-caption">PhonePe</p>
            <img src="/image/phonepay.jpg" alt="PhonePe" className="payment-image" />
          </div>

          <div className="bank-transfer">
            <p className="payment-caption">Bank Transfer</p>
            <h4>Account Name: GAJERA VIKASBHAI KANUBHAI</h4>
            <h4>Bank Account Number: 0149870540</h4>
            <h4>Bank Name: Kotak Mahindra</h4>
            <h4>IFSC Code: KKBK0002851</h4>
            <h4>Bank Branch: Kamrej Gujarat</h4>
            <button onClick={handleLoginRedirect} className="login-button">OK</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Payment;

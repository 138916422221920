import React, { useState } from "react";
import Layout from "./../../components/Layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "../../styles/AuthStyles.css";

const Register = () => {
    const [firstname, setFirstname] = useState("");
    const [middlename, setMiddlename] = useState("");
    const [lastname, setLastname] = useState("");
    const [address, setAddress] = useState("");
    const [aadhaar, setAadhaar] = useState("");
    const [pan, setPan] = useState("");
    const [photo, setPhoto] = useState(null); // User photo
    const [pathurl, setPathurl] = useState(""); // User photo URL
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [referenceCode, setReferenceCode] = useState("");

    const navigate = useNavigate();

    // Upload User Photo
    const uploadPhoto = () => {
        if (photo) {
            const formData = new FormData();
            formData.append("file", photo);

            axios.post("https://start-keo4.onrender.com/upload", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    setPathurl(res?.data?.file?.path);
                    toast.success("User photo uploaded successfully");
                })
                .catch((err) => {
                    console.error(err);
                    toast.error("Failed to upload user photo");
                });
        } else {
            toast.error("No user photo selected");
        }
    };


    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!pathurl ) {
            return toast.error("Please upload both user and Aadhaar photos");
        }

        try {
            const res = await axios.post("https://start-keo4.onrender.com/api/v1/auth/register", {
                firstname,
                middlename,
                lastname,
                address,
                aadhaar,
                pan,
                photo: pathurl, // Pass user photo path
                email,
                mobile,
                password,
                referenceCode,
            });

            toast.success("User registered successfully. Make payment after sending the code.");
            navigate("/payment"); // Redirect to login after successful registration
        } catch (error) {
            if (error.response && error.response.status === 400) {
                if (error.response.data.message === "Email or mobile is already registered.") {
                    toast.error("User already registered with this email or mobile.");
                } else if (error.response.data.message === "Invalid reference code.") {
                    toast.error("Invalid reference code.");
                } else {
                    toast.error(error.response.data.message);
                }
            } else {
                toast.error("Something went wrong. Please try again later.");
            }
        }
    };

    return (
        <Layout title="Register - Ecommer App">
            <Toaster />
            <div className="form-container" style={{ minHeight: "90vh" }}>
                <form onSubmit={handleSubmit}>
                    <h4 className="title">REGISTER FORM</h4>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your First Name"
                            required
                            autoFocus
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={middlename}
                            onChange={(e) => setMiddlename(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Middle Name"
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Last Name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Address"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={aadhaar}
                            onChange={(e) => setAadhaar(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Aadhaar Number"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={pan}
                            onChange={(e) => setPan(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your PAN Number"
                            required
                        />
                    </div>


                    {/* User Photo Upload */}
                    <div className="form-group">
                        <input
                            type="file"
                            onChange={(e) => setPhoto(e.target.files[0])}
                        />
                        <button type="button" onClick={uploadPhoto}>Upload User Photo</button>
                    </div>

                    <div className="mb-3">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Email"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="tel"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Mobile Number"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            placeholder="Enter Your Password"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            value={referenceCode}
                            onChange={(e) => setReferenceCode(e.target.value)}
                            className="form-control"
                            placeholder="Enter Reference Code"
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">REGISTER</button>
                </form>
            </div>
        </Layout>
    );
};

export default Register;

import React from "react";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
const Dashboard = () => {
  const [auth] = useAuth();

  console.log('User Data:', auth?.user); // Add this line to check the data

  return (
    <Layout title={"Dashboard - React App"}>
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <div className="card w-75 p-3">
              <h3>name: {auth?.user?.firstname || "N/A"}</h3>
              <h3>email: {auth?.user?.email || "N/A"}</h3>
              <h3>address: {auth?.user?.address || "N/A"}</h3>
              <h3>phone: {auth?.user?.mobile || "N/A"}</h3>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};


export default Dashboard;